<template>
  <div class="view-project">
    <div class="block">
      <dl>
        <dt>Sitemap</dt>
        <dd><a :href="project.sitemap">{{project.sitemap}}</a></dd>

        <dt>Appareil</dt>
        <dd>
          <agent v-for="a in project.userSettings.agents" :key="a" :agent="a" />
        </dd>

        <dt>Programmation</dt>
        <dd>{{project.userSettings.scheduleMode}}</dd>

        <dt>Courbe de chauffe</dt>
        <dd>{{project.userSettings.warmingCurveMode}}</dd>

        <dt>Economiseur de bande passante</dt>
        <dd>{{project.userSettings.bandwithSaverMode}}</dd>

        <dt>Clé privé</dt>
        <dd>{{project.key}}</dd>

      </dl>
    </div>
    <h3>Validation de propriété</h3>
    <div class="block">
      Afin de valider la propriété de votre site,
      merci de placer le fichier suivant à la racine de votre site

      <a href="data:text/plain;charset=utf-8,Prewarmit"
        class="file-download"
        :download="`prewarmit-${project.id}.txt`"
        target="_blank"
        ><mdicon name="fileOutline" /> prewarmit-{{project.id}}.txt</a>
    </div>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import Agent from '../components/Agent.vue';

export default {
  components: { Agent },
  computed: {
    ...mapState('projects', [
      'project',
    ]),
  },
  methods: {
    ...mapActions('projects', [

    ]),

  },

};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.file-download {
  font-size: 1.5em;
  text-decoration: none;
  margin: 1rem 0;
  svg {
    width: 32px;
    height: 32px;
  }
}
</style>
